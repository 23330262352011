import React from 'react';
import PropTypes from 'prop-types';

import etatCivilSrc from '../images/home/etat_civil.png';
import alimentationSrc from '../images/home/alimentation.jpg';
import mardiGrasSrc from '../images/home/mardi_gras.png';
import classeSrc from '../images/home/classe.png';
import enfantsSrc from '../images/home/enfants.jpg';

import Layout from '../components/layout/layout';
import { Text } from '../components/text';
import Image from '../components/image';
import { Container } from '../components/container';

const data = [
  {
    title: `Encadrement. Réinsertion.`,
    body: `Nous oeuvrons dans l'encadrement et la réinsertion des enfants vulnérables.`,
    image: {
      src: enfantsSrc,
      alt: `Encadrement. Réinsertion.`,
    },
  },
  {
    title: `Éducation inclusive.`,
    body: `MERERUE encadre des enfants en situation de handicap.`,
    image: {
      src: classeSrc,
      alt: `Éducation inclusive.`,
    },
  },
  {
    title: `Inscription à l'état civil.`,
    body: `Nous inscrivons les enfants à l'état civil.`,
    image: {
      src: etatCivilSrc,
      alt: `Inscription à l'état civil.`,
    },
  },
  {
    title: `Alimentation des enfants`,
    body: `Nous organisons des repas et des gouters, pour les enfants.`,
    image: {
      src: alimentationSrc,
      alt: `Nutrition`,
    },
  },
  {
    title: `Épanouissement des enfants`,
    body: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid architecto consequuntur cumque doloremque`,
    image: {
      src: mardiGrasSrc,
      alt: `Mardi gras`,
    },
  },
];
const CardWithImage = ({ title, body, image, order }) => {
  const orders = {
    1: 'order-1',
    2: 'order-2',
  };
  const reverseOrders = {
    2: 'order-1',
    1: 'order-2',
  };

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg lg:grid lg:grid-cols-2 lg:gap-8 items-center">
      <div className={`space-y-4 px-4 py-5 sm:p-6 ${orders[order]}`}>
        <Text as="h2" size="2xl" color="green-500" weight="extrabold">
          {title}
        </Text>
        <Text as="p" size="lg">
          {body}
        </Text>
      </div>
      <div className={`relative bg-gray-900 aspect-h-2 aspect-w-3 overflow-hidden ${reverseOrders[order]}`}>
        <img className="absolute h-full w-full object-cover" src={image.src} alt={image.alt} />
      </div>
    </div>
  );
};
CardWithImage.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  order: PropTypes.oneOf(['1', '2']),
};
CardWithImage.defaultProps = {
  order: '1',
};

const Hero = () => (
  <Container maxWidth="7xl" className="lg:grid lg:grid-cols-12 lg:gap-8 items-center py-8 lg:py-12">
    <div className="sm:text-center md:max-w-2xl md:mx-auto lg:mx-0 lg:text-left lg:col-span-7">
      <Text as="h1" size="4xl" weight="extrabold" color="green-500">
        MERERUE
      </Text>
      <p className="mt-3 sm:mt-5 sm:mx-auto lg:mx-0 text-base sm:text-lg md:text-xl sm:max-w-xl text-gray-500">
        La Maison d’Encadrement et de Réinsertion des Enfants de la Rue est une structure qui œuvre dans l'encadrement et la réinsertion des
        enfants vulnérables (orphelins, handicapés, enfants de la rue, enfants démunis). MERERUE les inscrit à l’état civil, les éduque et
        leur assure un suivi sanitaire et nutritionnel.
      </p>
    </div>
    <div className="mt-6 lg:mt-0 lg:col-span-5">
      <a href="https://youtu.be/IV7WTu7aZ_M" className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:ring">
        <Image className="rounded-lg" />
        <div className="absolute inset-0 w-full h-full flex items-center justify-center">
          <svg className="h-20 w-20 text-green-500" fill="currentColor" viewBox="0 0 84 84">
            <circle opacity="0.9" cx="42" cy="42" r="42" fill="white" />
            <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
          </svg>
        </div>
      </a>
    </div>
  </Container>
);

const IndexPage = () => (
  <Layout>
    <Hero />
    <Container maxWidth="7xl" className="space-y-16 py-12">
      <CardWithImage {...data[0]} />
      <CardWithImage order="2" {...data[1]} />
      <CardWithImage {...data[2]} />
      <CardWithImage order="2" {...data[3]} />
    </Container>
  </Layout>
);

export default IndexPage;
